import React from 'react';
import Layout from '../components/layout';
import ClientHeader from '../components/sections/ClientHeader';
import { LinkIcon, CalendarIcon, ComputerDesktopIcon } from '@heroicons/react/24/outline';


import people from '../data/people';

const links = [
    {
        name: 'eMoney',
        type: 'personal',
        description: 'Access your accounts, goals, spending, and investments.',
        path: 'https://wealth.emaplan.com/ema/kestrafinancial/roseman',
    },
    {
        name: 'Kestra',
        type: 'personal',
        description: 'Access your accounts, goals, spending, and investments.',
        path: 'https://investor.wealthscape.com/kestra/investor-index.html?login',
    },
    {
        name: 'American Funds',
        type: 'business',
        description: 'Access your accounts, goals, spending, and investments.',
        path: 'https://www.myplanrs.com/tf/myPLAN/Welcome?cz=3000518',
    },
    {
        name: 'Fidelity',
        type: 'business',
        description: 'Access your accounts, goals, spending, and investments.',
        path: 'https://401k.fidelity.com/public/content/401k/Home/Landing/',
    },
    {
        name: 'July',
        type: 'business',
        description: 'Access your accounts, goals, spending, and investments.',
        path: 'https://www.julyservices.com/participants',
    },
]


const clients = () => {

    return <Layout>

        <ClientHeader />
        <section className="">
            <div className="container mx-auto px-5 py-6 md:py-12">

                <div className="grid lg:grid-cols-2 lg:divide-x" >

                    <div className="flex flex-col py-16 px-2 md:px-12">
                        <div class='w-16 h-16 inline-flex sm:mr-4 sm:mb-0 mb-4 items-center justify-center rounded-full flex-shrink-0 bg-blue-500'>
                            <ComputerDesktopIcon className='h-8 w-8 text-blue-100' />
                        </div>
                        <small className='mt-6 text-blue-500'>Access your accounts</small>
                        <p class='text-left leading-relaxed text-gray-500'>
                            Access your online accounts by clicking on the links below.
                        </p>

                        <div className="grid grid-cols-1 md:grid-cols-2 items-stretch gap-4 mt-8 grow content-start">
                            {links.map((link) => (
                                <a className="h- px-6 py-3 text-lg leading-none rounded mr-4 inline-flex items-center hover:scale-105 active:scale-100  text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-400 drop-shadow hover:text-white"
                                    href={link.path}><LinkIcon className='h-4 w-4 mr-2 shrink-0' /> {link.name}</a>

                            ))}
                        </div>

                        <p className="mt-8 text-xs">
                            The information being provided is strictly as a courtesy. When you link to any of the web sites provided here, you are leaving this web site. Kestra IS and Kestra AS makes no representation as to the completeness or accuracy of information provided at these web sites. Nor is Kestra IS or Kestra AS liable for any direct or indirect technical or system issues or any consequences arising out of your access to or your use of third-party technologies, web sites, information and programs made available through this web site. When you access one of these web sites, you are leaving our web site and assume total responsibility and risk for your use of the web sites you are linking to.
                        </p>
                    </div>

                    <div className="flex flex-col py-16 px-2 md:px-12">
                        <div class='w-16 h-16 inline-flex sm:mr-4 sm:mb-0 mb-4 items-center justify-center rounded-full flex-shrink-0 bg-blue-500'>
                            <CalendarIcon className='h-8 w-8 text-blue-100' />
                        </div>
                        <small className='mt-6 text-blue-500'>Meet with your advisor</small>
                        <p class='grow text-left leading-relaxed text-gray-500'>
                            Conveniently schedule online by selecting an advisor below.
                        </p>


                        <div className="mt-8 grid grid-cols-2 xl:grid-cols-3 gap-8">
                            {people.filter(person => person.scheduler).map((person) => (
                                <a href={person.scheduler} className="flex flex-col items-center justify-start mb-4">
                                    <img className="w-36 h-36 rounded-full bg-gray-100 shadow-md border-2 border-gray-100 object-cover object-top" src={person.image} alt={person.fname} />
                                    <div className="flex flex-col mt-2">
                                        <p className="text-lg">{person.fname} {person.lname}</p>
                                    </div>
                                </a>
                            ))}

                        </div>
                    </div>

                </ div>
            </div >

        </section >
    </Layout>
};

export default clients;
